import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ky from 'ky';
import ms from 'ms';

const COOKIE_NAME_ACCESS_TOKEN = 'yr-access-token';
const ACCESS_TOKEN_EXPIRATION_THRESHOLD = ms('2 minutes');

export function getAccessToken() {
  return cookie.get(COOKIE_NAME_ACCESS_TOKEN);
}

export async function refreshAccessToken() {
  await ky.post('/_auth/refreshAccessToken', { json: {} });
}

export function getAccessTokenStatus() {
  const accessTokenExpirationTime = getAccessTokenExpirationTime();
  if (accessTokenExpirationTime == null) {
    return 'none';
  }

  return accessTokenExpirationTime > 0 ? 'valid' : 'expired';
}

function getAccessTokenExpirationTime(): number | undefined {
  const accessToken = getAccessToken();
  if (accessToken == null) {
    return undefined;
  }

  try {
    const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);
    const { exp: expirationTimeInSeconds } = decodedAccessToken;

    const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;

    // We assume the access token expires 2 minutes before it actually does
    // to give us enough time to refresh the access token before it actually
    // expires.
    return expirationTimeInMilliseconds - ACCESS_TOKEN_EXPIRATION_THRESHOLD;
  } catch (error) {
    return undefined;
  }
}

export function getTimeUntilAccessTokenExpires(): number | undefined {
  let expirationTime = getAccessTokenExpirationTime();
  if (expirationTime == null) {
    return undefined;
  }

  const timeUntilExpiry = expirationTime - Date.now();

  if (timeUntilExpiry < 0) {
    return 0;
  }

  return timeUntilExpiry;
}
