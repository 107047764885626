import ky from 'ky';
import { IUser } from '../model/user';
import { getTimeUntilAccessTokenExpires, refreshAccessToken } from './accessToken';
import { IAuthUserInfo } from '../model/auth';

export async function fetchUserInfo(): Promise<IUser> {
  const userInfo: IAuthUserInfo = await ky.get(`/_auth/userInfo`).json();

  const parsedAcceptedShareNrkAnonymouslyConsentVersion =
    userInfo['nrk/consent/sna'] != null ? parseInt(userInfo['nrk/consent/sna'], 10) : undefined;
  const acceptedShareNrkAnonymouslyConsentVersion =
    parsedAcceptedShareNrkAnonymouslyConsentVersion != null &&
    isNaN(parsedAcceptedShareNrkAnonymouslyConsentVersion) === false
      ? parsedAcceptedShareNrkAnonymouslyConsentVersion
      : undefined;

  return {
    name: userInfo.name,
    hasAcceptedShareNrkAnonymouslyConsent:
      acceptedShareNrkAnonymouslyConsentVersion != null && acceptedShareNrkAnonymouslyConsentVersion >= 1,
  };
}

export function scheduleRefresh() {
  const timeUntilAccessTokenExpires = getTimeUntilAccessTokenExpires();
  if (timeUntilAccessTokenExpires == null) {
    return;
  }

  window.setTimeout(refresh, timeUntilAccessTokenExpires);
}

export async function refresh() {
  try {
    await refreshAccessToken();
    scheduleRefresh();
  } catch (error) {
    // Clear cookies and reload the page if refreshing the access token fails.
    await clearCookies();
    window.location.reload();
  }
}

export async function clearCookies() {
  await ky.post('/_auth/clearCookies', { json: {} });
}
