// I fit any location we get from the APIs to this format,
// so that we can use the same interface
// from both `locations` and `bathingspots` response.
export interface IBathingSpotLocation {
  type: 'bathing-spot' | 'location';
  id: string;
  name: string;
  regionName: string;
  categoryName: string;
  position: {
    lat: number;
    lon: number;
  };
}

export interface IBathingSpotComboBoxShowMoreItem {
  type: 'show-more';
  query: string;
}

export function isBathingSpotLocation(
  item: IBathingSpotLocation | IBathingSpotComboBoxShowMoreItem
): item is IBathingSpotLocation {
  return item.type === 'bathing-spot' || item.type === 'location';
}
