import { Icon } from '@nrk/yr-icons';
import React from 'react';
import './ErrorMessage.scss';

interface IProps {
  children: React.ReactNode;
}

export function ErrorMessage(props: IProps) {
  const { children } = props;

  return (
    <p className="error-message" aria-live="assertive">
      <Icon className="error-message__icon" id="icon-info" size={2} />
      {children}
    </p>
  );
}
