import { useMachine } from '@xstate/react';
import { useCallback, useMemo, useState } from 'react';
import { Machine } from 'xstate';
import { getAccessToken, refreshAccessToken } from '../lib/accessToken';
import { clearCookies, fetchUserInfo, scheduleRefresh } from '../lib/auth';
import { IUser } from '../model/user';

interface IUserMachineSchema {
  states: {
    idle: {};
    refreshingAccessToken: {};
    fetchingUserInfo: {};
    notLoggedIn: {};
    loggedIn: {};
  };
}

type TUserMachineEvent =
  | { type: 'NO_ACCESS_TOKEN' }
  | { type: 'HAS_ACCESS_TOKEN' }
  | { type: 'RESOLVE' }
  | { type: 'REJECT' }
  | { type: 'LOGGED_OUT' };

type TUserMachineState = keyof IUserMachineSchema['states'];

interface IUseUserMachine {
  state: TUserMachineState;
  user?: IUser;
  handleLogOut: () => void;
}

export function useUserMachine() {
  const [current, send] = useMachine(userMachine, {
    actions: {
      idleEntry: () => {
        if (getAccessToken() != null) {
          send('HAS_ACCESS_TOKEN');
        } else {
          send('NO_ACCESS_TOKEN');
        }
      },

      refreshingAccessTokenEntry: async () => {
        try {
          await refreshAccessToken();
          send({ type: 'RESOLVE' });
        } catch (error) {
          send({ type: 'REJECT' });
        }
      },

      refreshingAccessTokenExit: (_context, event) => {
        if (event.type === 'RESOLVE') {
          scheduleRefresh();
        }
      },

      fetchingUserInfoEntry: async () => {
        try {
          const userInfo = await fetchUserInfo();
          setUser(userInfo);
          send({ type: 'RESOLVE' });
        } catch (error) {
          send({ type: 'REJECT' });
        }
      },

      notLoggedInEntry: async () => {
        await clearCookies();
      },
    },
  });

  const [user, setUser] = useState<IUser>();

  const handleLogOut = useCallback(() => {
    send({ type: 'LOGGED_OUT' });
  }, [send]);

  const value = useMemo<IUseUserMachine>(() => {
    return {
      state: current.value as TUserMachineState,
      user,
      handleLogOut,
    } as const;
  }, [current.value, user, handleLogOut]);

  return value;
}

const userMachine = Machine<undefined, IUserMachineSchema, TUserMachineEvent>({
  id: 'user',
  initial: 'idle',
  states: {
    idle: {
      on: {
        NO_ACCESS_TOKEN: 'notLoggedIn',
        HAS_ACCESS_TOKEN: 'refreshingAccessToken',
      },
      entry: 'idleEntry',
    },
    refreshingAccessToken: {
      on: {
        RESOLVE: 'fetchingUserInfo',
        REJECT: 'notLoggedIn',
      },
      entry: 'refreshingAccessTokenEntry',
      exit: 'refreshingAccessTokenExit',
    },
    fetchingUserInfo: {
      on: {
        RESOLVE: 'loggedIn',
        REJECT: 'notLoggedIn',
      },
      entry: 'fetchingUserInfoEntry',
    },
    notLoggedIn: {
      type: 'final',
      entry: 'notLoggedInEntry',
    },
    loggedIn: {
      on: {
        LOGGED_OUT: 'notLoggedIn',
      },
    },
  },
});
