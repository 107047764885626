import React from 'react';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import './Page.scss';

interface IProps {
  children: React.ReactNode;
}

export function Page(props: IProps) {
  const { children } = props;

  return (
    <div className="page">
      <div className="page__content">
        <div className="page__header">
          <Header />
        </div>

        <div className="page__body">{children}</div>

        <div className="page__footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
