import React from 'react';
import { ReactComponent as IllustrationStandingOnIsland } from '../../images/illustrations/standing-on-island.svg';
import { FullWidth } from '../FullWidth/FullWidth';
import './FullWidthStandingOnIslandIllustration.scss';

export function FullWidthStandingOnIslandIllustration() {
  return (
    <div className="full-width-standing-on-island-illustration">
      <FullWidth>
        <IllustrationStandingOnIsland className="full-width-standing-on-island-illustration__illustration" />
      </FullWidth>
    </div>
  );
}
