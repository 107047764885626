import React, { useState } from 'react';
import { HowToDialog } from '../HowToDialog/HowToDialog';
import './Footer.scss';

export function Footer() {
  const [showHowToDialog, setShowHowToDialog] = useState(false);

  return (
    <footer className="footer">
      <div className="footer__logos">
        <a
          className="footer__logo footer__logo--nrk"
          href="https://www.nrk.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer__logo-image footer__logo-image--nrk-img" src="/symbols/logo-nrk.svg" alt="NRK" />
        </a>
        <a
          className="footer__logo footer__logo--met"
          href="https://www.met.no/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer__logo-image footer__logo-image--met-img"
            src="/symbols/logo-met.svg"
            alt="Meteorologisk institutt"
          />
        </a>
        <a
          className="footer__logo footer__logo--reiseradioen"
          href="https://radio.nrk.no/serie/reiseradioen"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer__logo-image footer__logo-image--reiseradioen-img"
            src="/symbols/logo-reiseradioen.svg"
            alt="Reiseradioen"
          />
        </a>
      </div>

      <div className="footer__actions">
        <a className="footer__action" href="https://www.yr.no/nb" target="_blank" rel="noopener noreferrer">
          Yr nettsider
        </a>
        <a
          className="footer__action"
          href="https://hjelp.yr.no/hc/no/articles/206550539-Om-Yr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Om Yr
        </a>
        <a
          className="footer__action"
          href="https://hjelp.yr.no/hc/no/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kontakt oss
        </a>
        <a
          className="footer__action"
          href="https://hjelp.yr.no/hc/no/articles/360003337614-Om-personvern"
          target="_blank"
          rel="noopener noreferrer"
        >
          Personvern
        </a>
        <button
          className="footer__action"
          onClick={() => {
            setShowHowToDialog(true);
          }}
        >
          Slik måler du riktig temperatur
        </button>
      </div>

      {showHowToDialog && (
        <HowToDialog
          onClose={() => {
            setShowHowToDialog(false);
          }}
        />
      )}
    </footer>
  );
}
