import { Icon } from '@nrk/yr-icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import './Header.scss';
import { ReactComponent as YrLogoSecondary } from '../../images/symbols/logo-yr-secondary.svg';

export function Header() {
  const { state, user } = useAuth();

  return (
    <div className="header">
      <h1 className="header__title">
        <NavLink className="header__title-link" to="/" exact={true}>
          {/* eslint-disable-next-line jsx-a11y/aria-role */}
          <span className="header__title-text-container" role="text">
            <YrLogoSecondary aria-hidden="true" />
            <span className="visually-hidden">Yr</span> <span className="header__title-label">Badetemperaturer</span>
          </span>
        </NavLink>
      </h1>

      {state === 'loggedIn' && (
        <NavLink className="header__user" to="/profil" exact={true}>
          <Icon className="header__user-icon" id="icon-user" size={3} aria-hidden="true" />
          <span className="header__user-name">{user?.name}</span>
          <span className="visually-hidden">Profilside</span>
        </NavLink>
      )}
    </div>
  );
}
