// @ts-ignore
import yrTime from '@nrk/yr-time';
// @ts-ignore
import yrTimeLocaleNb from '@nrk/yr-time/locale/nb.json';

export function formatRelativeTime(time: string) {
  if (time == null) {
    return '';
  }
  const currentTime = yrTime.create();
  const relativeTime = yrTime.create(time);
  const yearDiff = relativeTime.diff(currentTime, 'years');
  const dayDiff = relativeTime.diff(currentTime, 'days');

  let format = '';

  if (dayDiff === 0) {
    format = '[i dag]';
  } else if (dayDiff === -1) {
    format = '[i går]';
  } else {
    format = 'D. MMM';
  }

  if (yearDiff !== 0) {
    format = `${format} YYYY`;
  }

  format = `${format}[, kl.] HH.mm`;

  return relativeTime.locale(yrTimeLocaleNb).format(format);
}
