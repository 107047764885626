import React from 'react';
import './NoResult.scss';

export const NoResult = () => {
  return (
    <div className="no-result">
      <h2 className="no-result__title">
        Beklager!
        <br />
        Vi finner ikke badeplassen du ser etter
      </h2>

      <p className="no-result__text">
        Sjekk at du har stavet stedsnavnet riktig eller prøv å søke etter et annet sted.
      </p>
      <p className="no-result__text">
        Finner du fortsatt ikke badeplassen din?{' '}
        <a href="https://hjelp.yr.no/hc/no/articles/4403308478866" target="_blank" rel="noreferrer">
          Slik kan du legge den til.
        </a>
      </p>
    </div>
  );
};
