import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { TFormReducerAction } from '../RegisterPage/formReducer';

const MAX_LENGTH = 280;
interface IProps {
  hidden: boolean;
  dispatchForm: React.Dispatch<TFormReducerAction>;
}
export const AddComment__Area = (props: IProps) => {
  const { hidden, dispatchForm } = props;

  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    dispatchForm({ type: 'COMMENT_CHANGE', value: currentText });
  }, [currentText, dispatchForm]);

  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentTextArea = event.target;

    setCurrentText(currentTextArea.value);
  };

  return (
    <div
      className={classNames('add-comment__area', {
        'add-comment__area--hidden': hidden,
      })}
    >
      <div className={'add-comment__area-text-area-wrapper'} data-replicated-value={currentText}>
        <textarea
          maxLength={MAX_LENGTH}
          className="add-comment__area-text-area"
          id="add-comment-text-area"
          onChange={(textarea) => onTextChange(textarea)}
        />
        <span className="add-comment__area-counter" aria-hidden={true}>
          {currentText.length}/{MAX_LENGTH}
        </span>
      </div>
      <div className="add-comment__area-info">
        Legg igjen mobilnummeret ditt i kommentarfeltet hvis du ønsker å bli ringt opp under sendingen. Kommentarer vil
        bare være synlige for redaksjonen i Reiseradioen og vil ikke vises på Yr sine sider.
      </div>
    </div>
  );
};
