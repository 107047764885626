import React from 'react';
import { useAuth } from '../../context/auth';
import { Banner } from '../Banner/Banner';

interface IProps {
  onClose: () => void;
}

export function AutoLoginBanner(props: IProps) {
  const { onClose } = props;

  const { user } = useAuth();

  const greeting = user?.name != null && user?.name.length > 0 ? `Hei ${user?.name}` : 'Hei';

  return (
    <Banner onClose={onClose}>
      {greeting}, du er nå logget inn i Yr Badetemperaturer fordi du allerede er innlogget et annet sted hos NRK
    </Banner>
  );
}
