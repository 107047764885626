import React from 'react';

interface IProps {
  value: number;
}

export function Temperature(props: IProps) {
  const { value } = props;

  return <span className="temperature">{formatValue(value)}°</span>;
}

// Convert number to a string with one decimal and a norwegian decimal separator
export function formatValue(value: number) {
  const [integerPart, decimalPart] = value.toFixed(1).split('.');

  return `${integerPart},${decimalPart}`;
}
