import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { Button } from '../Button/Button';
import { PageHeading } from '../PageHeading/PageHeading';
import './ProfilePage.scss';

export const ProfilePage = () => {
  const { user } = useAuth();
  const greeting = user?.name ? `Hei ${user?.name}!` : 'Hei!';
  const logguturl = encodeURIComponent(`${window.HOST}/_auth/logout`);
  const exiturl = encodeURIComponent(`${window.HOST}/profil`);

  return (
    <div className="profile-page">
      <PageHeading className="profile-page__hero" title="Profil">
        {greeting}
      </PageHeading>
      <p className="profile-page__ingress">Takk for at du er med og bidrar til å måle badetemperaturer!</p>
      <Button
        className="profile-page__my-profile-button"
        type="link"
        target="external"
        href={`${window.OIDC_API}/profil`}
      >
        Min profil
      </Button>
      <Button
        className="profile-page__log-out-button"
        styleType="secondary"
        type="link"
        target="server"
        href={`${window.OIDC_API}/tentativloggut?logguturl=${logguturl}&exiturl=${exiturl}`}
      >
        Logg ut
      </Button>
      <Link className="profile-page__home-link" to="/">
        Tilbake til forsiden
      </Link>
    </div>
  );
};
