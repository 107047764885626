import { Icon } from '@nrk/yr-icons';
import React from 'react';
import './Select.scss';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'className'> {
  value: string;
  children: React.ReactNode;
}

export const Select = React.forwardRef((props: IProps, ref: React.Ref<HTMLSelectElement>) => {
  const { children, ...otherProps } = props;

  return (
    <div className="select">
      <select ref={ref} className="select__select" {...otherProps}>
        {children}
      </select>
      <div className="select__background"></div>

      <Icon className="select__icon" id="icon-chevron-down" size={3} />
    </div>
  );
});
