import { Icon } from '@nrk/yr-icons';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { searchBathingSpots, searchLocation } from '../../lib/api';
import { IBathingSpotLocation } from '../../model/bathingSpot';
import { Button } from '../Button/Button';
import { Loader } from '../Loader/Loader';
import { NoResult } from '../NoResult/NoResult';
import { PageHeading } from '../PageHeading/PageHeading';
import { SearchLocationList } from '../SearchLocationList/SearchLocationList';
import './SearchPage.scss';

const MAX_SEARCH_RESULTS = 20;

export const SearchPage = () => {
  const history = useHistory();

  const [locations, setLocations] = useState<IBathingSpotLocation[]>([]);
  const [bathingSpots, setBathingSpots] = useState<IBathingSpotLocation[]>([]);
  const [bathingSpotsLocationIds, setBathingSpotsLocationIds] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const query = useMemo(() => {
    const parsed = queryString.parse(history.location.search);
    const { q } = parsed;

    if (typeof q !== 'string') {
      return undefined;
    }

    return q;
  }, [history.location.search]);

  // Search for bathing spots when the query string changes.
  // In practice this only triggers when the page first loads.
  useEffect(() => {
    const controller = new AbortController();

    async function asyncEffect() {
      if (query == null || query.length === 0) {
        return;
      }

      setIsFetching(true);
      const { ok, body } = await searchBathingSpots(query, controller);

      if (ok && body != null) {
        const bathingSpotsLocationIds = body.map((location: IBathingSpotLocation) => location.id);
        setBathingSpots(body);
        setBathingSpotsLocationIds(bathingSpotsLocationIds);
        setIsFetching(false);
      }
    }

    asyncEffect();

    return () => {
      controller.abort();
    };
  }, [query]);

  // Search for locations when the query string changes.
  // In practice this only triggers when the page first loads.
  useEffect(() => {
    const controller = new AbortController();

    async function asyncEffect() {
      if (query == null || query.length === 0) {
        return;
      }

      const { ok, body } = await searchLocation(query, controller);
      if (ok && body != null) {
        setLocations(body);
      }
    }

    asyncEffect();

    return () => {
      controller.abort();
    };
  }, [query]);

  // We remove all locations that are registered bathing spots from the locations response.
  // This is to avoid rendering duplicate locations.
  const locationsWithoutBathingSpots = locations.filter(
    (location: IBathingSpotLocation) => bathingSpotsLocationIds.includes(location.id) === false
  );

  // Combine bathingspots and locations search to one list
  const searchResults = bathingSpots.concat(locationsWithoutBathingSpots).slice(0, MAX_SEARCH_RESULTS);

  return (
    <div className="search-page">
      <PageHeading className="search-page__heading" title="Søk etter badeplass">
        Søk etter badeplass
      </PageHeading>

      <form className="search-page__form" action="/søk">
        <div className="search-page__input-container">
          <input
            className="search-page__input"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            name="q"
            defaultValue={query}
            type="search"
            placeholder="Søk etter en badeplass"
          />
          <div className="search-page__input-background"></div>
          <Icon className="search-page__input-icon" id="icon-search" size={3} />
        </div>

        <span className="search-page__button">
          <Button type="button" buttonType="submit">
            Søk
          </Button>
        </span>
      </form>

      {isFetching === true && (
        <div className="search-page__loader">
          <Loader />
        </div>
      )}

      {isFetching === false && searchResults.length > 0 && (
        <>
          <h3 className="search-page__result-heading">Viser {searchResults.length} treff</h3>
          <SearchLocationList locations={searchResults} />

          <p className="search-page__help-link">
            Vises ikke badeplassen din?{' '}
            <a href="https://hjelp.yr.no/hc/no/articles/4403308478866" target="_blank" rel="noreferrer">
              Slik kan du legge den til.
            </a>
          </p>
        </>
      )}

      {isFetching === false && searchResults.length === 0 && (
        <div className="search-page__no-result">
          <NoResult />
        </div>
      )}
    </div>
  );
};
