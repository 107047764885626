import cookie from 'js-cookie';
import ms from 'ms';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUserMachine } from '../hooks/useUserMachine';
import { useLocation } from 'react-router-dom';

interface IAuthState {
  state: ReturnType<typeof useUserMachine>['state'];
  user: ReturnType<typeof useUserMachine>['user'];
  showAutoLoginBanner: boolean;
  closeAutoLoginBanner: () => void;
  showAutoLogoutBanner: boolean;
  closeAutoLogoutBanner: () => void;
}

const AuthStateContext = React.createContext<IAuthState | undefined>(undefined);

interface IProps {
  children: React.ReactNode;
}

const OPENID_PROVIDER_IFRAME_ID = 'openid-provider';
const POST_SESSION_STATE_CHECK_TIMEOUT = ms('2 seconds');

export function AuthProvider(props: IProps) {
  const { children } = props;

  const { state, user, handleLogOut } = useUserMachine();

  const currentLocation = useLocation();

  const [showAutoLoginBanner, setShowAutoLoginBanner] = useState(
    typeof window.sessionStorage !== 'undefined' && window.sessionStorage.getItem('showAutoLoginBanner') === 'true'
  );

  const [showAutoLogoutBanner, setShowAutoLogoutBanner] = useState(false);

  const closeAutoLoginBanner = useCallback(() => {
    setShowAutoLoginBanner(false);

    if (typeof window.sessionStorage !== 'undefined') {
      window.sessionStorage.removeItem('showAutoLoginBanner');
    }
  }, []);

  const closeAutoLogoutBanner = useCallback(() => {
    setShowAutoLogoutBanner(false);
  }, []);

  const postSessionStateCheckTimeoutId = useRef<number>();

  const handleMessageEvent = useCallback(
    (event: any) => {
      // Only handle events from our openid provider iframe
      if (event.origin !== window.OIDC_API) {
        return;
      }

      // Ignore events if the user is on the login error page
      // to prevent infinite reload loop.
      if (currentLocation.pathname === '/innloggingsfeil') {
        return;
      }

      // With two badetemperaturer-dev.yr.no tabs open, and logging out in a third stage-innlogging.nrk.no
      // tab, only one of the two badetemperaturer-dev.yr.no managed to log out after receiving an "ended"
      // event. The other tab remained "logged in" (cookies still there) but kept posting "none" to the iframe
      // and receiving "unchanged" in return.
      switch (event.data) {
        case 'unchanged': {
          break;
        }

        // Automatically login the user by redirecting to the `/_auth/login` endpoint
        // which redirects to innlogging.nrk.no and sets the correct cookies.
        case 'changed': {
          // Set a session storage variable to show the user a banner informing them
          // that they have been automatically logged in after the login redirect.
          if (typeof window.sessionStorage !== 'undefined') {
            // TODO(scb): Set this to false as soon as the banner is shown
            // or simplify this to just rely on query param in URL?
            window.sessionStorage.setItem('showAutoLoginBanner', 'true');
          }

          const loginUrl = `/_auth/login?fromUrl=${encodeURIComponent(window.location.href)}&autoLogin=true`;
          document.location.replace(loginUrl);

          break;
        }

        case 'ended': {
          setShowAutoLogoutBanner(true);
          handleLogOut();

          break;
        }
      }

      if (event.data === 'unchanged') {
        postSessionStateCheckTimeoutId.current = window.setTimeout(
          postSessionStateCheck,
          POST_SESSION_STATE_CHECK_TIMEOUT
        );
      }
    },
    [handleLogOut, currentLocation.pathname]
  );

  useEffect(() => {
    // Clear post session state check timeout on unmount
    if (postSessionStateCheckTimeoutId.current != null) {
      window.clearTimeout(postSessionStateCheckTimeoutId.current);
    }
  }, []);

  useEffect(() => {
    // Automatically remove `showAutoLoginBanner` from session storage
    // after a short delay. This won't close the banner, but will prevent
    // the banner from being shown if the user accepts consent e.g.
    setTimeout(() => {
      if (typeof window.sessionStorage !== 'undefined') {
        window.sessionStorage.removeItem('showAutoLoginBanner');
      }
    }, 1000);
  }, []);

  // Render the OpenID provider in a hidden iframe when the component is mounted
  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    async function asyncUseEffect() {
      if (document.getElementById(OPENID_PROVIDER_IFRAME_ID) == null) {
        await createHiddenIframe(
          OPENID_PROVIDER_IFRAME_ID,
          `${window.OIDC_API}${window.OIDC_IFRAME_PATH}?d=${new Date().getTime()}`
        );

        postSessionStateCheck();
      }
    }

    asyncUseEffect();

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  const value = useMemo(() => {
    return {
      state,
      user,
      showAutoLoginBanner,
      closeAutoLoginBanner,
      showAutoLogoutBanner,
      closeAutoLogoutBanner,
    };
  }, [state, user, showAutoLoginBanner, closeAutoLoginBanner, showAutoLogoutBanner, closeAutoLogoutBanner]);

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

function createHiddenIframe(id: string, src: string) {
  if (document.getElementById(id) != null) {
    throw new Error('Iframe already exists');
  }

  return new Promise((resolve, reject) => {
    const body = document.querySelector('body');
    if (body == null) {
      throw new Error('Missing body');
    }

    const hiddenIframe = document.createElement('iframe');
    hiddenIframe.id = id;
    hiddenIframe.src = src;
    hiddenIframe.style.display = 'none';
    hiddenIframe.onload = resolve;
    hiddenIframe.onerror = reject;

    body.appendChild(hiddenIframe);
  });
}

function isIframe(input: HTMLElement): input is HTMLIFrameElement {
  return input != null && input.tagName === 'IFRAME';
}

function postSessionStateCheck() {
  const openIdProviderIframe = document.getElementById(OPENID_PROVIDER_IFRAME_ID);
  if (openIdProviderIframe == null) {
    throw new Error('Iframe not found');
  }

  if (isIframe(openIdProviderIframe) && openIdProviderIframe.contentWindow != null) {
    const message = createPostMessage();
    const openIdProviderOrigin = window.OIDC_API;

    openIdProviderIframe.contentWindow.postMessage(message, openIdProviderOrigin);
  }
}

function createPostMessage() {
  const clientId = window.OIDC_CLIENT_ID;
  const sessionState = cookie.get(window.COOKIE_NAME_SESSION_STATE) || 'none';

  return `${clientId} ${sessionState}`;
}
