// @ts-ignore
import CoreDialog from '@nrk/core-dialog/jsx';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Dialog.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Icon } from '@nrk/yr-icons';

interface IProps {
  size?: 'small' | 'large';
  onClose: () => void;
  children: React.ReactNode;
}

export function Dialog(props: IProps) {
  const { size = 'large', onClose, children } = props;
  const modalRoot = document.querySelector('#modal-root');

  const dialogRef = useRef<HTMLDivElement>(null);
  const visibleDialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dialogElement = dialogRef.current;
    if (dialogElement == null) {
      return;
    }

    disableBodyScroll(dialogElement, {
      reserveScrollBarGap: true,
    });

    return () => {
      enableBodyScroll(dialogElement);
    };
  }, []);

  function handleDialogClick(event: React.MouseEvent) {
    if (visibleDialogRef.current == null) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return false;
    }

    // Close the dialog if the user clicks outside the dialog
    if (visibleDialogRef.current.contains(event.target) === false) {
      onClose();
    }
  }

  if (modalRoot == null) {
    throw new Error('Could not find modal root');
  }

  return ReactDOM.createPortal(
    <div className={`dialog dialog--${size}`} ref={dialogRef} onClick={handleDialogClick}>
      <CoreDialog
        className="dialog__core-dialog"
        backdrop={false}
        onDialogToggle={(event: any) => {
          if (event.target.hidden === true) {
            onClose();
          }
        }}
      >
        <div className="dialog__visible-dialog" ref={visibleDialogRef}>
          {children}
          <button className="dialog__close-button" onClick={onClose}>
            <Icon id="icon-cross" size={3} />
          </button>
        </div>
      </CoreDialog>
    </div>,
    modalRoot
  );
}
