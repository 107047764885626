import { Icon } from '@nrk/yr-icons';
import React from 'react';
import './HowToDialog.scss';
import { Dialog } from '../Dialog/Dialog';

interface IProps {
  onClose: () => void;
}

export function HowToDialog(props: IProps) {
  const { onClose } = props;

  return (
    <Dialog onClose={onClose}>
      <div className="how-to-dialog">
        <h1 className="how-to-dialog__title" tabIndex={-1}>
          Slik måler du riktig badetemperatur
        </h1>

        <Icon className="how-to-dialog__icon" id="icon-bathing-spot" size={6} />
        <p className="how-to-dialog__text">Sørg for at du har valgt riktig sted å måle temperaturen for.</p>

        <Icon className="how-to-dialog__icon" id="icon-temperature" size={6} />
        <p className="how-to-dialog__text">
          Husk å måle der hvor folk flest bader. Du skal måle temperaturen i åpent vann, ikke i basseng, på svært grunt
          vann eller steder som kan ha høyere vanntemperatur enn det publikum opplever.
        </p>

        <Icon className="how-to-dialog__icon" id="icon-ruler" size={6} />
        <p className="how-to-dialog__text">
          Det bør være minst 1 meter dypt hvor du måler. (helst 1,5-2 m). La termometeret ligge i vannet i minst 5
          minutter, 20 cm under overflaten.
        </p>

        <p className="how-to-dialog__warning">
          Dersom du systematisk registrerer høyere badetemperatur enn det som er reelt, vil du bli permanent utestengt
          fra badetemperaturregistreringen.
        </p>
      </div>
    </Dialog>
  );
}
