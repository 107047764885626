import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './PageHeading.scss';

interface IProps extends Omit<React.HTMLAttributes<HTMLHeadingElement>, 'tabIndex'> {
  title?: string;
  children: React.ReactNode;
}

export function PageHeading(props: IProps) {
  const { children, className, title, ...otherProps } = props;

  const headingRef = useRef<HTMLHeadingElement>(null);

  // Automatically focus this page heading on first mount
  useEffect(() => {
    if (headingRef.current != null) {
      headingRef.current.focus();
    }
  }, []);

  return (
    <h2 {...otherProps} className={classNames('page-heading', className)} tabIndex={-1} ref={headingRef}>
      <Helmet defaultTitle="Yr Badetemperaturer" titleTemplate="Yr Badetemperaturer - %s">
        {title != null && <title>{title}</title>}
      </Helmet>

      {children}
    </h2>
  );
}
