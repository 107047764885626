import React from 'react';
import { isInputTimeSupported } from '../../lib/featureDetection';
import { Input } from '../Input/Input';

interface IProps extends React.ComponentProps<typeof Input> {}

export const TimeInput = React.forwardRef((props: IProps, ref: React.Ref<HTMLInputElement>) => {
  const { value, onFocus, onChange, ...otherProps } = props;

  return (
    <Input
      {...otherProps}
      ref={ref}
      value={value}
      type={isInputTimeSupported ? "time" : "text"}
      inputMode={isInputTimeSupported ? undefined : 'numeric'}
      pattern={isInputTimeSupported ? undefined : '(0[1-9]|1[0-9]|2[0-3])[:.][0-5][0-9]'}
      maxLength={5}
      onFocus={(event) => {
        // Focus the content of the time input when selected
        // to make it faster to change the time. We need to do
        // this after a short timeout to make this work in Safari.
        // A delay of 10ms seems to work well, anything less and
        // the text input doesn't always get selected in Safari.
        const input = event.target;
        setTimeout(() => {
          input.select();
        }, 10);

        if (onFocus != null) {
          onFocus(event);
        }
      }}
      onChange={(event) => {
        if (isInputTimeSupported === false && value != null) {
          // If the input element's new value contains any character other than `0-9` and `:`
          // we ignore it and reset the input's value to the previous value.
          if (/^[0-9:]*$/.test(event.target.value.toString()) === false) {
            event.target.value = value != null ? value.toString() : '';
          }

          // Automatically add a colon after the user has typed two characters
          // to make it easier to input a valid time like `12:00`.
          // We only do this if the input previously contained exactly one number,
          // otherwise we'd end up adding back the colon whenever someone tried to delete
          // the last character of a time input field that contained `12:`.
          // This does mean we don't automatically add a colon when someone pastes `12`
          // into an empty time input field, but that's hopefully a minor issue.
          if (/^\d$/.test(value.toString()) && /^\d{2}$/.test(event.target.value)) {
            event.target.value = `${event.target.value}:`;
          }
        }

        if (onChange != null) {
          onChange(event);
        }
      }}
    />
  );
});
