import React from 'react';
import './FullWidth.scss';

interface IProps {
  children: React.ReactNode;
}

export function FullWidth(props: IProps) {
  const { children } = props;

  return <div className="full-width">{children}</div>;
}
