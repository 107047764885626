import { Icon } from '@nrk/yr-icons';
import React, { useEffect, useState } from 'react';
import { TFormReducerAction } from '../RegisterPage/formReducer';
import './AddComment.scss';
import { AddComment__Area } from './AddComment__Area';

interface IProps {
  dispatchForm: React.Dispatch<TFormReducerAction>;
}

export const AddComment = (props: IProps) => {
  const { dispatchForm } = props;

  const isLargeDisplay = window.matchMedia('(min-width: 768px)').matches;

  const [hideTextArea, setHideTextArea] = useState(!isLargeDisplay);

  /**
   * We need to wait for the text area to be shown before we can focus it, so we use a useEffect to ensure that.
   * This effect will only be triggered once when the user clicks the button that we render on smaller devices
   */
  useEffect(() => {
    if (isLargeDisplay === false && hideTextArea === false) {
      document.getElementById('add-comment-text-area')?.focus();
    }
  }, [isLargeDisplay, hideTextArea]);

  return (
    <div className="add-comment">
      {hideTextArea === true && (
        <button
          className="add-comment__button"
          onClick={(event) => {
            event.preventDefault();
            if (hideTextArea === true) {
              setHideTextArea(false);
            }
          }}
        >
          <Icon id="icon-plus" className="add-comment__button-icon" size={2.5} />
          <span className="add-comment__label-text">
            <span>Send oss en kommentar</span> <span>(valgfritt) </span>
            <span className="visually-hidden">Maks. 280 tegn</span>
          </span>
        </button>
      )}
      {(isLargeDisplay === true || hideTextArea === false) && (
        <label className="add-comment__label" htmlFor="add-comment-text-area">
          <span className="add-comment__label-text">
            <span>Send oss en kommentar</span> <span>(valgfritt) </span>
            <span className="visually-hidden">Maks. 280 tegn</span>
          </span>
        </label>
      )}
      <AddComment__Area hidden={hideTextArea} dispatchForm={dispatchForm} />
    </div>
  );
};
