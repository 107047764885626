import React from 'react';
import { Banner } from '../Banner/Banner';

interface IProps {
  onClose: () => void;
}

export function AutoLogoutBanner(props: IProps) {
  const { onClose } = props;

  return <Banner onClose={onClose}>Du har blitt automatisk logget ut fordi du logget ut et annet sted hos NRK</Banner>;
}
