import classNames from 'classnames';
import React from 'react';
import './Input.scss';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  fakeValue?: string;
  showError?: boolean;
}

export const Input = React.forwardRef((props: IProps, forwardedRef: React.Ref<HTMLInputElement>) => {
  const { fakeValue, showError = false, ...otherProps } = props;

  return (
    <div className="input">
      <input {...otherProps} className={classNames('input__input', { 'input__input--error': showError })} />
      <div className="input__background"></div>
      {fakeValue != null && <span className="input__fake-value">{fakeValue}</span>}
    </div>
  );
});
