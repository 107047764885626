import { Icon } from '@nrk/yr-icons';
import React from 'react';
import { Button } from '../Button/Button';

interface IProps {
  coreSuggestId: string;
  onInputChange: (event: React.FormEvent<HTMLInputElement>) => void;
}
export const BathingSpotSearchInput = (props: IProps) => {
  const { coreSuggestId, onInputChange } = props;
  return (
    <div className="bathing-spots-combo-box__container">
      <div className="bathing-spot-search-input">
        <input
          className="bathing-spot-search-input__input"
          placeholder="Søk etter en badeplass"
          name="q"
          onChange={(event) => onInputChange(event)}
          list={coreSuggestId}
        />
        <div className="bathing-spot-search-input__background"></div>
        <Icon className="bathing-spot-search-input__icon" id="icon-search" size={3} />
      </div>

      <span className="bathing-spots-combo-box__button">
        <Button type="button" buttonType="submit">
          Søk
        </Button>
      </span>
    </div>
  );
};
