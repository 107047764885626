import React, { useState, useEffect } from 'react';
import { Temperature } from '../Temperature/Temperature';
import './WaterTemperatureList.scss';
import { IAPILocationWaterTemperature } from '../../model/api';
import { fetchRegisteredWaterTemperaturesByLocationId } from '../../lib/api';
import { formatRelativeTime } from '../../lib/time';
import { sentenceCase } from '../../lib/string';

interface IProps {
  locationId: string;
}

export function WaterTemperatureList(props: IProps) {
  const { locationId } = props;

  const [temperatures, setTemperatures] = useState<IAPILocationWaterTemperature[]>([]);

  useEffect(() => {
    async function registeredWaterTemperatures() {
      const response = await fetchRegisteredWaterTemperaturesByLocationId(locationId);
      if (response.ok && response.body != null) {
        setTemperatures(response.body);
      }
    }

    registeredWaterTemperatures();
  }, [locationId]);

  if (temperatures == null) {
    return null;
  }

  if (temperatures.length === 0) {
    return (
      <div className="water-temperature-list">
        <p className="water-temperature-list__empty-message">Ingen nylig registrerte målinger.</p>
      </div>
    );
  }

  return (
    <div className="water-temperature-list">
      <table className="water-temperature-list__table">
        <thead className="water-temperature-list__table-header">
          <tr>
            <th>Tidspunkt</th>
            <th>Registreringskilde</th>
            <th>Temperatur</th>
          </tr>
        </thead>
        <tbody>
          {temperatures.map((temperature) => {
            const timeLabel = sentenceCase(formatRelativeTime(temperature.time));

            return (
              <tr key={temperature.time} className="water-temperature-list__item">
                <td className="water-temperature-list__time">{timeLabel}</td>
                <td className="water-temperature-list__type">
                  {temperature.sourceDisplayName == null ? 'Manuell registrering' : temperature.sourceDisplayName}
                </td>
                <td className="water-temperature-list__temperature">
                  <Temperature value={temperature.temperature} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
