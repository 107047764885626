import { Icon } from '@nrk/yr-icons';
import React from 'react';
import { Button } from '../Button/Button';
import { FullWidthStandingOnIslandIllustration } from '../FullWidthStandingOnIslandIllustration/FullWidthStandingOnIslandIllustration';
import { PageHeading } from '../PageHeading/PageHeading';
import './NotLoggedInPage.scss';

export function NotLoggedInPage() {
  const loginUrl = `/_auth/login?fromUrl=${encodeURIComponent(window.location.href)}`;

  return (
    <div className="not-logged-in-page">
      <PageHeading className="not-logged-in-page__heading" title="Logg inn">
        Hvordan var <br />
        temperaturen
        <br /> i vannet?
      </PageHeading>

      <h3 className="not-logged-in-page__subheading">Yr og Reiseradioen samler inn badetemperaturer fra hele Norge.</h3>

      <Button className="not-logged-in-page__login-button" type="link" href={loginUrl} target="server">
        Logg inn eller opprett profil
      </Button>
      <p className="not-logged-in-page__details">
        <Icon className="not-logged-in-page__details-icon" id="icon-info" size={2} />
        For å kunne registrere badetemperaturer må du være logget på din NRK-bruker.{' '}
      </p>

      <FullWidthStandingOnIslandIllustration />
    </div>
  );
}
