// These polyfills must be imported first
import '@webcomponents/custom-elements';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './components/App/App';
import { AuthProvider } from './context/auth';
import './styles/index.scss';
import coreAnalytics from '@nrk/core-analytics';

const queryClient = new QueryClient();

window.coreAnalytics = coreAnalytics;

window.coreAnalytics('initialize', {
  ga: window.SERVER_ENV === 'production' ? 'UA-25535414-11' : '0',
  trackerName: 'yrbadetemperaturer',
});

// We want to use immer in IE11
enableES5();

const isAppActive = document.querySelector('html').classList.contains('app-active');

if (isAppActive) {
  const root = document.getElementById('root');

  // Empty the root element which initially includes a message
  // about the browser being unsupported.
  root.innerHTML = '';

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Helmet defaultTitle="Yr Badetemperaturer" />
          <App />
        </AuthProvider>
      </Router>
    </QueryClientProvider>,
    root
  );
}
