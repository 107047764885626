import { Icon } from '@nrk/yr-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { IAPILocation } from '../../model/api';
import './BathingSpotList.scss';

interface IProps {
  locations: IAPILocation[];
}

export function BathingSpotList(props: IProps) {
  const { locations } = props;

  return (
    <ul className="bathing-spot-list">
      {locations.map((location) => (
        <li key={location.id} className="bathing-spot-list__item">
          <Link className="bathing-spot-list__item-link" to={`registrer/${location.id}/${location.name}`}>
            <Icon id="icon-bathing-spot" size={3} />
            <span className="bathing-spot-list__name">{location.name}</span>
            <Icon id="icon-chevron-right" size={3} />
          </Link>
        </li>
      ))}
    </ul>
  );
}
