// @ts-ignore
import yrTime from '@nrk/yr-time';

export interface IFormState {
  temperature: {
    value?: number;
    status: 'valid' | 'invalid';
    isPristine: boolean;
    showError: boolean;
  };
  date: {
    value: string;
  };
  time: {
    value: string;
    min: string;
    max: string;
    status: 'valid' | 'invalid' | 'in-the-future';
    isPristine: boolean;
    showError: boolean;
  };
  comment: {
    value: string;
  };
  isValid: boolean;
  status: 'idle' | 'submitting' | 'success' | 'error';
}

type TFormReducerActionTemperatureChange = {
  type: 'TEMPERATURE_CHANGE';
  value: string;
  isValid: boolean;
};

type TFormReducerActionDateChange = {
  type: 'DATE_CHANGE';
  value: string;
  isValid: boolean;
};

type TFormReducerActionTimeChange = {
  type: 'TIME_CHANGE';
  value: string;
  isValid: boolean;
};

type TFormReducerActionTemperatureBlur = {
  type: 'TEMPERATURE_BLUR';
};

type TFormReducerActionCommentChange = {
  type: 'COMMENT_CHANGE';
  value: string;
};

type TFormReducerActionTimeBlur = {
  type: 'TIME_BLUR';
};

type TFormReducerActionCancel = {
  type: 'CANCEL';
};

type TFormReducerActionReset = {
  type: 'RESET';
};

type TFormReducerActionRequestStart = {
  type: 'REQUEST_START';
};

type TFormReducerActionRequestSuccess = {
  type: 'REQUEST_SUCCESS';
};

type TFormReducerActionRequestError = {
  type: 'REQUEST_ERROR';
};

export type TFormReducerAction =
  | TFormReducerActionTemperatureChange
  | TFormReducerActionDateChange
  | TFormReducerActionTimeChange
  | TFormReducerActionTemperatureBlur
  | TFormReducerActionTimeBlur
  | TFormReducerActionCommentChange
  | TFormReducerActionCancel
  | TFormReducerActionReset
  | TFormReducerActionRequestStart
  | TFormReducerActionRequestSuccess
  | TFormReducerActionRequestError;

export function formReducer(draft: IFormState, action: TFormReducerAction) {
  switch (action.type) {
    case 'TEMPERATURE_CHANGE': {
      const { value: stringValue, isValid } = action;
      const numberValue = stringValue !== '' ? Number(stringValue) : undefined;

      draft.temperature.value = numberValue;
      draft.temperature.isPristine = false;
      draft.temperature.status = isValid ? 'valid' : 'invalid';

      // While the user is typing we don't need to show an error
      draft.temperature.showError = false;

      break;
    }

    case 'DATE_CHANGE': {
      const { value } = action;

      draft.date.value = value;

      // The time may become invalid if changing from yesterday to today
      // and the time is later than the current hour.
      draft.time.status = isTimeBeforeMaxTime({ date: value, time: draft.time.value, dateMax: draft.time.max })
        ? 'valid'
        : 'in-the-future';
      draft.time.showError = draft.time.status !== 'valid';

      break;
    }

    case 'TIME_CHANGE': {
      const { value } = action;

      // The time itself may be a valid time, but if the date is today
      // we also need to check that the time is not after the max time.
      let isValid = action.isValid;
      if (isValid === true) {
        draft.time.status = isTimeBeforeMaxTime({ date: draft.date.value, time: value, dateMax: draft.time.max })
          ? 'valid'
          : 'in-the-future';
      } else {
        draft.time.status = 'invalid';
      }

      draft.time.value = value;
      draft.time.isPristine = false;

      // While the user is typing we don't need to show an error
      draft.time.showError = false;

      break;
    }

    case 'TEMPERATURE_BLUR': {
      draft.temperature.showError = draft.temperature.isPristine === false && draft.temperature.status !== 'valid';

      break;
    }

    case 'TIME_BLUR': {
      draft.time.showError = draft.time.isPristine === false && draft.time.status !== 'valid';

      break;
    }

    case 'COMMENT_CHANGE': {
      const { value } = action;

      draft.comment.value = value;

      break;
    }

    case 'CANCEL': {
      draft.status = 'idle';
      break;
    }

    case 'RESET': {
      return createInitialFormState();
    }

    case 'REQUEST_START': {
      draft.status = 'submitting';
      break;
    }

    case 'REQUEST_SUCCESS': {
      draft.status = 'success';
      break;
    }

    case 'REQUEST_ERROR': {
      draft.status = 'error';
      break;
    }

    default:
      throw new Error();
  }
}

function isTimeBeforeMaxTime({ date, time, dateMax }: { date: string; time: string; dateMax: string }) {
  const maxTime = yrTime.create(dateMax);
  const newTime = yrTime.create(`${date}T${time}`);

  return newTime.isBefore(maxTime) || newTime.isSame(maxTime);
}

export function createInitialFormState(): IFormState {
  const initialTime = yrTime.create();
  const minTime = initialTime.subtract(1, 'day').startOf('day');
  const maxTime = initialTime;

  return {
    temperature: {
      status: 'invalid',
      isPristine: true,
      showError: false,
    },
    date: {
      value: initialTime.format('YYYY-MM-DD'),
    },
    time: {
      value: initialTime.format('HH:mm'),
      min: minTime.format('YYYY-MM-DDTHH:mm'),
      max: maxTime.format('YYYY-MM-DDTHH:mm'),
      status: 'valid',
      isPristine: true,
      showError: false,
    },
    comment: {
      value: '',
    },
    status: 'idle',
    isValid: false,
  };
}
