import React, { useEffect, useState } from 'react';
import { searchBathingSpots } from '../../lib/api';
import { IBathingSpotComboBoxShowMoreItem, IBathingSpotLocation } from '../../model/bathingSpot';
import './BathingSpotsComboBox.scss';
import { BathingSpotSearchInput } from './BathingSpotSearchInput';
import { SuggestedBathingSpots } from './SuggestedBathingSpots';

// How many suggested locations we display in the search dialog.
const NUMBER_OF_SUGGESTED_LOCATIONS = 5;

export const BathingSpotsComboBox = () => {
  const [currentQuery, setCurrentQuery] = useState<string>();
  const [bathingSpotsComboBoxItems, setBathingSpotsComboBoxItems] = useState<
    Array<IBathingSpotLocation | IBathingSpotComboBoxShowMoreItem>
  >([]);

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    setCurrentQuery(query);
  };

  useEffect(() => {
    if (currentQuery == null || currentQuery.length === 0) {
      // Clear suggested locations list if there is no query
      setBathingSpotsComboBoxItems([]);
      return;
    }

    const controller = new AbortController();

    async function asyncEffect() {
      if (currentQuery == null) {
        return;
      }

      const { ok, body } = await searchBathingSpots(currentQuery, controller);

      if (ok && body != null) {
        // We don't show all the suggested locations in the list
        const bathingSpotsComboBoxItems: Array<IBathingSpotLocation | IBathingSpotComboBoxShowMoreItem> = body.splice(
          0,
          NUMBER_OF_SUGGESTED_LOCATIONS
        );

        bathingSpotsComboBoxItems.push({ type: 'show-more', query: currentQuery });

        setBathingSpotsComboBoxItems(bathingSpotsComboBoxItems);
      }
    }

    asyncEffect();

    return () => {
      controller.abort();
    };
  }, [currentQuery]);

  const coreSuggestId = 'bathing-spots-core-suggest';

  return (
    <div className="bathing-spots-combo-box">
      <form action="søk">
        <BathingSpotSearchInput onInputChange={onInputChange} coreSuggestId={coreSuggestId} />
        <SuggestedBathingSpots bathingSpots={bathingSpotsComboBoxItems} coreSuggestId={coreSuggestId} />
      </form>
    </div>
  );
};
