import { Icon } from '@nrk/yr-icons';
import React from 'react';
import './Banner.scss';

interface IProps {
  children: React.ReactNode;
  onClose: () => void;
}

export function Banner(props: IProps) {
  const { onClose, children } = props;

  return (
    <button className="banner" aria-live="polite" aria-label="Trykk for å lukke dette varselet" onClick={onClose}>
      <span className="banner__text">{children}</span>

      <span className="banner__close-button">
        <Icon id="icon-cross" size={3} />
      </span>
    </button>
  );
}
