import CoreSuggest from '@nrk/core-suggest/jsx';
import { Icon } from '@nrk/yr-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { IBathingSpotComboBoxShowMoreItem, IBathingSpotLocation, isBathingSpotLocation } from '../../model/bathingSpot';

interface IProps {
  bathingSpots: Array<IBathingSpotLocation | IBathingSpotComboBoxShowMoreItem>;
  coreSuggestId: string;
}

export const SuggestedBathingSpots = (props: IProps) => {
  const { bathingSpots, coreSuggestId } = props;

  return (
    <CoreSuggest id={coreSuggestId}>
      <ul className="suggested-bathing-spots">
        {bathingSpots.map((item: IBathingSpotLocation | IBathingSpotComboBoxShowMoreItem, index: number) => {
          if (isBathingSpotLocation(item)) {
            return (
              <li className="suggested-bathing-spots__item" key={item.id}>
                <Link className="suggested-bathing-spots__link" to={`registrer/${item.id}/${item.name}`}>
                  <span className="suggested-bathing-spots__icon">
                    <Icon id="icon-bathing-spot" size={3} />
                  </span>
                  <span className="suggested-bathing-spots__location">
                    <span className="suggested-bathing-spots__location-name">{item.name}</span>
                    <span className="suggested-bathing-spots__region">
                      {item.categoryName} - {item.regionName}
                    </span>
                  </span>
                </Link>
              </li>
            );
          } else {
            return (
              <li className="suggested-bathing-spots__item" key={`${item.query}`}>
                <Link className="suggested-bathing-spots__link" to={`søk?q=${item.query}`}>
                  <span className="suggested-bathing-spots__icon">
                    <Icon id="icon-search" size={3} />
                  </span>
                  <span className="suggested-bathing-spots__show-more">Vis flere forslag</span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </CoreSuggest>
  );
};
