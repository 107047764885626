import React from 'react';
import './Loader.scss';

export function Loader() {
  return (
    <div className="loader">
      <span className="loader__animation-bounce" />
      <span className="loader__animation-bounce" />
      <span className="loader__animation-bounce" />
    </div>
  );
}
