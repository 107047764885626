import React from 'react';
import { Link } from 'react-router-dom';
import './SearchLocationList.scss';
import { Icon } from '@nrk/yr-icons';
import { IBathingSpotLocation } from '../../model/bathingSpot';

interface IProps {
  locations: IBathingSpotLocation[];
}

export const SearchLocationList = (props: IProps) => {
  const { locations } = props;
  return (
    <ul className="search-location-list">
      {locations.map((location: IBathingSpotLocation) => {
        return (
          <li className="search-location-list__item" key={location.id}>
            <Link className="search-location-list__link" to={`registrer/${location.id}/${location.name}`}>
              {location.type === 'bathing-spot' && (
                <span className="search-location-list__icon">
                  <Icon id="icon-bathing-spot" size={3}></Icon>
                </span>
              )}
              <div className="search-location-list__item-text-wrapper">
                <span className="search-location-list__location">{location.name}</span>
                <span className="search-location-list__region">
                  {location.categoryName} - {location.regionName}
                </span>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
