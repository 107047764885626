import React from 'react';
import { ReactComponent as IllustrationCrying } from '../../images/illustrations/crying.svg';
import { Button } from '../Button/Button';
import { PageHeading } from '../PageHeading/PageHeading';
import './LoginErrorPage.scss';

export function LoginErrorPage() {
  return (
    <div className="login-error-page">
      <IllustrationCrying className="login-error-page__illustration" />

      <PageHeading className="login-error-page__heading" title="Innloggingsfeil">
        Oops!
      </PageHeading>
      <p className="login-error-page__text">
        Det oppstod en feil slik at vi ikke kunne logge deg inn. Vennligst prøv å logge inn på nytt.
      </p>

      <Button className="login-error-page__login-button" type="link" href="/_auth/login" target="server">
        Logg inn
      </Button>
    </div>
  );
}
