// @ts-ignore
import yrTime from '@nrk/yr-time';
import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import L from 'leaflet';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useImmerReducer } from 'use-immer';
import { ReactComponent as IllustrationDiving } from '../../images/illustrations/diving.svg';
import { ReactComponent as IllustrationGoingSwimming } from '../../images/illustrations/going-swimming.svg';
import { fetchReiseradioenInSeason, fetchYrLocation, registerWaterTemperature } from '../../lib/api';
import { Button } from '../Button/Button';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { HowToDialog } from '../HowToDialog/HowToDialog';
import { Input } from '../Input/Input';
import { Loader } from '../Loader/Loader';
import { PageHeading } from '../PageHeading/PageHeading';
import { Select } from '../Select/Select';
import { TimeInput } from '../TimeInput/TimeInput';
import { WaterTemperatureList } from '../WaterTemperatureList/WaterTemperatureList';
import { createInitialFormState, formReducer, IFormState, TFormReducerAction } from './formReducer';
import { AddComment } from '../AddComment/AddComment';
import './RegisterPage.scss';
import 'leaflet/dist/leaflet.css';

export const RegisterPage = () => {
  const { locationId } = useParams<{ locationId: string }>();

  const { data: yrLocation } = useQuery(`yr-location-${locationId}`, () => fetchYrLocation(locationId));
  const { data: reiseradioenInSeason } = useQuery(`reiseradioen-in-season`, fetchReiseradioenInSeason);
  const [form, dispatchForm] = useImmerReducer<IFormState, TFormReducerAction>(formReducer, createInitialFormState());
  const [showHowToDialog, setShowHowToDialog] = useState(false);

  // The map element is not rendered until we have finished fetching the location
  // so we need to use `useCallback` instead of `useRef` + `useEffect`.
  // See https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const mapRef = useCallback(
    (element: HTMLDivElement) => {
      if (element == null) {
        return;
      }

      // Don't reinitialize the map
      if (element.childElementCount > 0) {
        return;
      }

      // Only initialize the map if we have a location
      if (yrLocation == null) {
        return;
      }

      const position: [number, number] = [yrLocation.position.lat, yrLocation.position.lon];

      const map = L.map(element, {
        center: position,
        zoom: 8,
        maxZoom: 10,
        minZoom: 6,
        dragging: false,
        attributionControl: false,
      });

      L.tileLayer('https://{s}-kartcache.nrk.no/tiles/yr_basemap_mercator_2019_512/{z}/{x}/{y}.jpg', {}).addTo(map);
      L.tileLayer('https://{s}-kartcache.nrk.no/tiles/yr_overlay_mercator_2019_512/{z}/{x}/{y}.png', {}).addTo(map);

      const pinIcon = L.icon({
        iconUrl: '/icons/pin.png',
        iconSize: [50, 50],
        iconAnchor: [25, 50 - 8],
        shadowUrl: '/icons/shadow.png',
        shadowSize: [50, 50],
        shadowAnchor: [25, 25],
      });

      L.marker(position, { icon: pinIcon }).addTo(map);
    },
    [yrLocation]
  );

  async function submit() {
    if (form.temperature.status !== 'valid' || form.time.status !== 'valid') {
      return;
    }

    if (form.temperature.value == null || form.time.value == null) {
      return;
    }

    dispatchForm({
      type: 'REQUEST_START',
    });

    const response = await registerWaterTemperature({
      locationId,
      temperature: form.temperature.value,
      time: parseISO(`${form.date.value}T${form.time.value}`).toISOString(),
      comment: form.comment.value,
    });

    dispatchForm({
      type: response.ok ? 'REQUEST_SUCCESS' : 'REQUEST_ERROR',
    });
  }

  function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault();
    submit();
  }

  // Don't render anything if we haven't finished fetching data.
  // Ideally we'd show a loading spinner when loading data
  // and an error message if loading fails.
  if (yrLocation == null || reiseradioenInSeason == null) {
    return null;
  }

  const locationName = yrLocation.name;

  if (form.status === 'success') {
    return (
      <div className="register-page">
        <div className="register-page__success">
          <IllustrationGoingSwimming className="register-page__success-going-swimming" />
          <IllustrationDiving className="register-page__success-diving" />

          <PageHeading className="register-page__success-heading" title="Registrering fullført">
            Takk for bidraget!
          </PageHeading>
          <p className="register-page__success-subheading">
            Du har registrert {form.temperature.value}° for {locationName}. <br />
            Den siste målingen for badeplassen blir vist på Yr.no i opp til fem dager.
          </p>

          <Button
            type="link"
            href={`https://www.yr.no/nb/badetemperaturer/`}
            target="external"
            className="register-page__go-to-yr-button"
          >
            Gå til badetemperaturer på Yr
          </Button>

          <button
            className="register-page__reset-button"
            onClick={() => {
              // Scroll to the top of the page in case the user has
              // scrolled down a little.
              window.scrollTo(0, 0);

              dispatchForm({
                type: 'RESET',
              });
            }}
          >
            Registrer ny måling
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="register-page">
      <PageHeading className="register-page__heading" title={locationName}>
        Registrer badetemperatur for {locationName}
      </PageHeading>

      <div className="register-page__form-container">
        {form.status === 'error' && (
          <ErrorMessage>Kunne ikke sende inn målingen din. Vennligst prøv å send på nytt.</ErrorMessage>
        )}

        <form
          className={classNames('register-page__form', {
            'register-page__form--submitting': form.status === 'submitting',
          })}
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          {/* This fieldset only exists so we can set the form to disabled while submitting */}
          <fieldset className="register-page__form-fieldset" disabled={form.status === 'submitting'}>
            <div className="register-page__form-grid">
              <div className="register-page__temperature-label-cell">
                <label className="register-page__form-label" htmlFor="register-page__temperature-input">
                  Vanntemperatur
                </label>
              </div>

              <div className="register-page__temperature-input-cell">
                <Input
                  id="register-page__temperature-input"
                  type="number"
                  step="0.1"
                  placeholder="°C"
                  min="-3"
                  max="30"
                  required={true}
                  value={form.temperature.value ?? ''}
                  showError={form.temperature.showError}
                  onChange={(event) => {
                    dispatchForm({
                      type: 'TEMPERATURE_CHANGE',
                      value: event.target.value,
                      isValid: event.target.checkValidity(),
                    });
                  }}
                  onBlur={() => {
                    dispatchForm({ type: 'TEMPERATURE_BLUR' });
                  }}
                />
              </div>

              <div className="register-page__date-label-cell">
                <label className="register-page__form-label" htmlFor="register-page__date-select">
                  Dag for måling
                </label>
              </div>

              <div className="register-page__date-input-cell">
                <Select
                  id="register-page__date-select"
                  value={form.date.value}
                  onChange={(event) => {
                    dispatchForm({
                      type: 'DATE_CHANGE',
                      value: event.target.value,
                      isValid: event.target.checkValidity(),
                    });
                  }}
                >
                  <option value={yrTime.create(form.time.max).format('YYYY-MM-DD')}>I dag</option>
                  <option value={yrTime.create(form.time.min).format('YYYY-MM-DD')}>I går</option>
                </Select>
              </div>

              <div className="register-page__time-label-cell">
                <label className="register-page__form-label" htmlFor="register-page__time-input">
                  Tidspunkt for måling
                </label>
              </div>

              <div className="register-page__time-input-cell">
                <TimeInput
                  id="register-page__time-input"
                  required={true}
                  value={form.time.value}
                  showError={form.time.showError}
                  onChange={(event) => {
                    dispatchForm({
                      type: 'TIME_CHANGE',
                      value: event.target.value,
                      isValid: event.target.checkValidity(),
                    });
                  }}
                  onBlur={() => {
                    dispatchForm({ type: 'TIME_BLUR' });
                  }}
                />
              </div>
            </div>

            {(form.temperature.showError || form.time.showError) && (
              <ul className="register-page__error-list">
                {form.temperature.showError && (
                  <li className="register-page__error-message">
                    <ErrorMessage>Temperaturen må være mellom -3 og 30 grader med maks en desimal</ErrorMessage>
                  </li>
                )}
                {form.time.showError && (
                  <li className="register-page__error-message">
                    <ErrorMessage>
                      {form.time.status === 'invalid' && 'Ugyldig klokkeslett'}
                      {form.time.status === 'in-the-future' &&
                        `Tidspunktet kan ikke være etter ${yrTime.create(form.time.max).format('HH:mm')}`}
                    </ErrorMessage>
                  </li>
                )}
              </ul>
            )}

            <button
              type="button"
              className="register-page__how-to-button"
              onClick={() => {
                setShowHowToDialog(true);
              }}
            >
              <Icon className="register-page__how-to-icon" id="icon-temperature" size={2} />
              Slik måler du riktig temperatur
            </button>

            {showHowToDialog && (
              <HowToDialog
                onClose={() => {
                  setShowHowToDialog(false);
                }}
              />
            )}

            {reiseradioenInSeason.reiseradioenInSeason && (
              <div className="register-page__add-comment">
                <AddComment dispatchForm={dispatchForm} />
              </div>
            )}

            <Button
              className="register-page__form-submit"
              type="button"
              buttonType="submit"
              disabled={form.temperature.status !== 'valid' || form.time.status !== 'valid'}
            >
              Send inn
            </Button>

            <p className="register-page__details">
              <Icon className="register-page__details-icon" id="icon-info" size={2} />
              Målingen vil vises på Yr.no i opp til fem dager.
            </p>
          </fieldset>
        </form>

        {form.status === 'submitting' && (
          <div className="register-page__loader">
            <Loader />
            <p className="register-page__loader-text" aria-live="assertive">
              Registrerer målingen din
            </p>
          </div>
        )}
      </div>

      <div className="register-page__map" ref={mapRef}></div>

      <h3 className="register-page__water-temperature-list-heading">Siste målinger for {locationName}</h3>
      <WaterTemperatureList locationId={locationId} />
    </div>
  );
};
