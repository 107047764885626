import React from 'react';
import { useAuth } from '../../context/auth';
import { ReactComponent as IllustrationGoingSwimming } from '../../images/illustrations/going-swimming.svg';
import { Button } from '../Button/Button';
import { FullWidthStandingOnIslandIllustration } from '../FullWidthStandingOnIslandIllustration/FullWidthStandingOnIslandIllustration';
import { PageHeading } from '../PageHeading/PageHeading';
import './AcceptConsentPage.scss';

export function AcceptConsentPage() {
  const { user } = useAuth();
  const greeting = user?.name ? `Hei ${user?.name}!` : 'Hei!';

  return (
    <div className="accept-consent-page">
      <PageHeading className="accept-consent-page__heading" title="Aksepter brukervilkår">
        {greeting}
      </PageHeading>
      <p className="accept-consent-page__subheading">Velkommen til registrering av badetemperaturer på Yr.</p>

      <p className="accept-consent-page__details">
        Siden du er pålogget andre NRK-tjenester så slipper du å logge inn på nytt her, men vi trenger ditt samtykke til
        å bruke målingene dine.
      </p>

      <Button
        className="accept-consent-page__button"
        type="link"
        target="server"
        href={`${window.OIDC_API}/delsamtykke/aksepter/sna/1?exitUrl=${window.location.href}`}
      >
        Fortsett til brukervilkår
      </Button>
      <IllustrationGoingSwimming className="accept-consent-page__going-swimming" />
      <FullWidthStandingOnIslandIllustration />
    </div>
  );
}
