import classNames from 'classnames';
import { Icon, TIconId } from '@nrk/yr-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

interface IBaseButtonProps {
  styleType?: 'primary' | 'secondary';
}

interface IButtonProps extends IBaseButtonProps {
  type: 'button';
  buttonType?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  children: React.ReactNode;
}

interface IIconButtonProps extends IBaseButtonProps {
  type: 'icon-button';
  buttonType?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  icon: TIconId;
  disabled?: boolean;
  children: React.ReactNode;
}

interface ILinkProps extends IBaseButtonProps {
  type: 'link';
  className?: string;
  href: string;
  target?: 'app' | 'server' | 'external';
  children: React.ReactNode;
}

interface IIconLinkProps extends IBaseButtonProps {
  type: 'icon-link';
  className?: string;
  href: string;
  target?: 'app' | 'server' | 'external';
  icon: TIconId;
  children: React.ReactNode;
}

export function Button(props: IButtonProps | IIconButtonProps | ILinkProps | IIconLinkProps) {
  const { styleType = 'primary' } = props;
  const styleTypeClassName = `button--${styleType}`;

  if (props.type === 'button') {
    const { className, buttonType, onClick, disabled, children } = props;

    return (
      <button
        className={classNames('button', className, styleTypeClassName)}
        type={buttonType}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  if (props.type === 'icon-button') {
    const { className, buttonType, onClick, icon, disabled, children } = props;

    return (
      <button
        className={classNames('button', className, styleTypeClassName)}
        type={buttonType}
        disabled={disabled}
        onClick={onClick}
      >
        <span className="button__invisible-label">{children}</span>
        <Icon id={icon} size={3} />
      </button>
    );
  }

  if (props.type === 'link') {
    const { className, href, target = 'client', children } = props;

    if (target === 'external') {
      return (
        <a
          className={classNames('button', className, styleTypeClassName)}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }

    if (target === 'server') {
      return (
        <a className={classNames('button', className, styleTypeClassName)} href={href}>
          {children}
        </a>
      );
    }

    return (
      <Link className={classNames('button', className, styleTypeClassName)} to={href}>
        {children}
      </Link>
    );
  }

  if (props.type === 'icon-link') {
    const { className, href, target = 'client', icon, children } = props;

    if (target === 'external') {
      return (
        <a
          className={classNames('button', className, styleTypeClassName)}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="button__invisible-label">{children}</span>
          <Icon id={icon} size={3} />
        </a>
      );
    }

    if (target === 'server') {
      return (
        <a className={classNames('button', className, styleTypeClassName)} href={href}>
          <span className="button__invisible-label">{children}</span>
          <Icon id={icon} size={3} />
        </a>
      );
    }

    return (
      <Link className={classNames('button', className, styleTypeClassName)} to={href}>
        <span className="button__invisible-label">{children}</span>
        <Icon id={icon} size={3} />
      </Link>
    );
  }

  return null;
}
