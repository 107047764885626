import React, { useEffect, useState } from 'react';
import { fetchRegisteredBathingspotsByUser } from '../../lib/api';
import { IAPILocation } from '../../model/api';
import { BathingSpotList } from '../BathingSpotList/BathingSpotList';
import { BathingSpotsComboBox } from '../BathingSpotsComboBox/BathingSpotsComboBox';
import { PageHeading } from '../PageHeading/PageHeading';
import './HomePage.scss';

export function HomePage() {
  const [bathingSpots, setBathingSpots] = useState<IAPILocation[]>([]);

  useEffect(() => {
    async function registeredWaterTemperatures() {
      const response = await fetchRegisteredBathingspotsByUser();
      if (response.ok && response.body != null) {
        setBathingSpots(response.body);
      }
    }

    registeredWaterTemperatures();
  }, []);

  return (
    <div className="home-page">
      <PageHeading className="home-page__heading">Finn badeplass</PageHeading>

      <BathingSpotsComboBox />
      {bathingSpots.length > 0 && (
        <>
          <h2 className="home-page__bathing-spot-list-heading">Mine badesteder</h2>

          <div className="home-page__bathing-spot-list">
            <BathingSpotList locations={bathingSpots} />
          </div>
        </>
      )}
    </div>
  );
}
