import { ReactComponent as IconSpriteSheet } from '@nrk/yr-icons/dist/icon-spritesheet.svg';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { HomePage } from '../../components/HomePage/HomePage';
import { useAuth } from '../../context/auth';
import { AcceptConsentPage } from '../AcceptConsentPage/AcceptConsentPage';
import { AutoLoginBanner } from '../AutoLoginBanner/AutoLoginBanner';
import { AutoLogoutBanner } from '../AutoLogoutBanner/AutoLogoutBanner';
import { Loader } from '../Loader/Loader';
import { LoginErrorPage } from '../LoginErrorPage/LoginErrorPage';
import { NotLoggedInPage } from '../NotLoggedInPage/NotLoggedInPage';
import { Page } from '../Page/Page';
import { ProfilePage } from '../ProfilePage/ProfilePage';
import { RegisterPage } from '../RegisterPage/RegisterPage';
import { SearchPage } from '../SearchPage/SearchPage';
import './App.scss';

export function App() {
  const { state } = useAuth();
  const reactRouterLocation = useLocation();

  useEffect(() => {
    window.coreAnalytics('pageview', { page: reactRouterLocation.pathname });
  }, [reactRouterLocation]);

  if (state === 'idle') {
    return null;
  }

  if (state === 'refreshingAccessToken' || state === 'fetchingUserInfo') {
    return (
      <div className="app">
        <div className="app__loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <IconSpriteSheet className="app__icon-spritesheet" />

      {state === 'loggedIn' ? <App__LoggedIn /> : <App__NotLoggedIn />}
    </div>
  );
}

function App__LoggedIn() {
  const { user, showAutoLoginBanner, closeAutoLoginBanner } = useAuth();

  if (user == null) {
    return null;
  }

  const missingConsentRoutes = (
    <Page>
      <Switch>
        <Route path="/profil" exact={true}>
          <ProfilePage />
        </Route>
        <Route path="*" exact={true}>
          <AcceptConsentPage />
        </Route>
      </Switch>
    </Page>
  );

  const hasConsentRoutes = (
    <Page>
      <Switch>
        <Route path="/profil" exact={true}>
          <ProfilePage />
        </Route>
        <Route path="/registrer/:locationId/:locationName" exact={true}>
          <RegisterPage />
        </Route>
        <Route path="/søk" exact={true}>
          <SearchPage />
        </Route>
        <Route path="/" exact={true}>
          <HomePage />
        </Route>
      </Switch>
    </Page>
  );

  return (
    <>
      {showAutoLoginBanner && <AutoLoginBanner onClose={closeAutoLoginBanner} />}
      {user?.hasAcceptedShareNrkAnonymouslyConsent ? hasConsentRoutes : missingConsentRoutes}
    </>
  );
}

function App__NotLoggedIn() {
  const { showAutoLogoutBanner, closeAutoLogoutBanner } = useAuth();

  return (
    <>
      {showAutoLogoutBanner && <AutoLogoutBanner onClose={closeAutoLogoutBanner} />}

      <Page>
        <Switch>
          <Route path="/innloggingsfeil" exact={true}>
            <LoginErrorPage />
          </Route>
          {/*
            This route should not be exact because we want to show the not logged in page
            even when you open a register page directly via a bookmark or a link. You could
            also end up directly on the register page when not logged in if you are on that
            page while logging out of innlogging.nrk.no in another tab.
          */}
          <Route path="/">
            <NotLoggedInPage />
          </Route>
        </Switch>
      </Page>
    </>
  );
}
