import ky, { Options } from 'ky';
import {
  IAPIBathingSpot,
  IAPILocation,
  IAPILocationWaterTemperature,
  IApiReiseradioenInSeason,
  IAPISearchLocation,
} from '../model/api';
import { IBathingSpotLocation } from '../model/bathingSpot';
import { getAccessToken } from './accessToken';

async function request({ url, payload, controller }: { url: string; payload?: object; controller?: AbortController }) {
  const method = payload != null ? 'post' : 'get';

  const options: Options = {};

  if (controller != null) {
    options.signal = controller.signal;
  }

  if (payload != null) {
    options.json = payload;
  }

  if (url.indexOf('https://www.yr.no/api/v0/') === 0) {
    // Don't use the cache at all for CORS requests to https://www.yr.no/api/v0/.
    // https://www.yr.no/api/v0/ only sets the `Access-Control-Allow-Origin: *`
    // header for requests from https://badetemperaturer.yr.no/ (and -dev and -local),
    // so a user that has been to https://www.yr.no/nb before going to https://badetemperaturer.yr.no/
    // might have a cached version of https://www.yr.no/api/v0/locations/1-72837?language=nb
    // without the `Access-Control-Allow-Origin: *` header.
    options.cache = 'no-store';
  }

  const accessToken = getAccessToken();
  if (accessToken != null) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return await ky[method](url, options);
}

export async function registerWaterTemperature({
  locationId,
  temperature,
  time,
  comment,
}: {
  locationId: string;
  temperature: number;
  time: string;
  comment: string;
}) {
  try {
    await request({
      url: `/api/v0/locations/${locationId}/watertemperatures`,
      payload: { temperature, timestamp: time, comment },
    });

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
    };
  }
}

export async function fetchRegisteredWaterTemperaturesByLocationId(locationId: string) {
  try {
    const response = await request({ url: `/api/v0/locations/${locationId}/watertemperatures` });
    const body: IAPILocationWaterTemperature[] = await response.json();

    return { ok: true, body };
  } catch (error) {
    return { ok: false };
  }
}

export async function fetchRegisteredBathingspotsByUser() {
  try {
    const response = await request({ url: '/api/v0/user/bathingspots' });
    const body: IAPILocation[] = await response.json();

    return { ok: true, body };
  } catch (error) {
    return { ok: false };
  }
}

export async function searchBathingSpots(query: string, controller: AbortController) {
  try {
    const response = await request({
      url: `/api/v0/locations/searchbathingspots?q=${query}`,
      controller,
    });

    const body: IAPIBathingSpot[] = await response.json();

    const bathingSpots: IBathingSpotLocation[] = body.map((location: IAPIBathingSpot) => {
      return {
        type: 'bathing-spot',
        id: location.locationId,
        name: location.name,
        categoryName: location.categoryName,
        regionName: location.regionName,
        position: {
          lat: location.lat,
          lon: location.lon,
        },
      };
    });

    return { ok: true, body: bathingSpots };
  } catch (error) {
    return { ok: false };
  }
}

export async function fetchYrLocation(locationId: string) {
  const response = await request({
    url: `https://www.yr.no/api/v0/locations/${locationId}?language=nb`,
  });

  const body: IBathingSpotLocation = await response.json();
  return body;
}

export async function fetchReiseradioenInSeason() {
  const response = await request({
    url: '/api/v0/watertemperatures/reiseradioen/inseason',
  });

  const body: IApiReiseradioenInSeason = await response.json();
  return body;
}

export async function searchLocation(query: string, controller: AbortController) {
  try {
    const queryLimitedToNorway = `${query} Norge`;
    const response = await request({
      url: `https://www.yr.no/api/v0/locations/Search?q=${queryLimitedToNorway}&language=nb`,
      controller,
    });

    const body: IAPISearchLocation = await response.json();

    const locations: IBathingSpotLocation[] = body._embedded.location.map((location: IAPILocation) => {
      return {
        type: 'location',
        id: location.id,
        name: location.name,
        categoryName: location.category.name,
        regionName: getFullRegionNameFromLocation(location),
        position: location.position,
      };
    });

    return {
      ok: true,
      body: locations,
    };
  } catch (error) {
    return { ok: false };
  }
}

function getFullRegionNameFromLocation(location: IAPILocation) {
  let regionName = '';

  if (location.region != null && location.region.name != null) {
    regionName += location.region.name;
  }

  if (location.subregion != null && location.subregion.name != null) {
    regionName += `, ${location.subregion.name}`;
  }

  return regionName;
}
